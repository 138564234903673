import dayjs from 'dayjs'
import i18next from 'i18next'

export const EMPTY_VALUE = '---'

export enum CHALLENGE_TYPE {
	COMPETITIVE = 'COMPETITIVE',
	HARMONIC = 'HARMONIC'
}

export enum GENERATE_STATE {
	IDLE = 'IDLE',
	SENDING = 'SENDING',
	CHECKING = 'CHECKING',
	SAVING = 'SAVING'
}

export enum ANIMATED_THUMBNAIL_STATUS {
	STARTED = 'started',
	COMPLETED = 'completed'
}

export enum DASHBOARD_PANEL {
	SUBSCRIPTION = 'SUBSCRIPTION',
	USERS = 'USERS',
	PROGRAMS = 'PROGRAMS',
	PROGRAM_ENGAGEMENT_TREND_PER_DAY = 'PROGRAM_ENGAGEMENT_TREND_PER_DAY',
	PROGRAM_ENGAGEMENT_TREND = 'PROGRAM_ENGAGEMENT_TREND',
	EXERCISES_VIEWS = 'EXERCISES_VIEWS',
	EXERCISES_VIEWS_TREND = 'EXERCISES_VIEWS_TREND',
	EXERCISES_FAVOURITES = 'EXERCISES_FAVOURITES',
	PAYMENTS = 'PAYMENTS',
	RECIPE_BOOKS = 'RECIPE_BOOKS'
}

export enum LOGIN_TYPES {
	EMAIL = 'EMAIL',
	FACEBOOK = 'FACEBOOK',
	GOOGLE = 'GOOGLE',
	APPLE = 'APPLE'
}

export enum USER_TABS {
	SUBSCRIPTIONS = 'subscriptions',
	INVOICES = 'invoices',
	PREFERENCES = 'preferences',
	PROGRAMS = 'programs',
	GENERAL = 'general',
	DEVICES = 'devices'
}

export enum EXERCISE_BODY_PART {
	SHOULDERS = 'SHOULDERS',
	CHEST = 'CHEST',
	BICEPS = 'BICEPS',
	TRICEPS = 'TRICEPS',
	FOREARM = 'FOREARM',
	ABS = 'ABS',
	QUADS = 'QUADS',
	CALVES = 'CALVES',
	GLUTES = 'GLUTES',
	LATS = 'LATS',
	HIPS = 'HIPS',
	FULL_BODY = 'FULL_BODY'
}

export enum SOCIALS {
	FACEBOOK = 'FACEBOOK',
	GOOGLE = 'GOOGLE',
	EMAIL = 'EMAIL'
}

export enum ADMIN_ROLE {
	ADMIN = 'ADMIN',
	SUPERADMIN = 'SUPERADMIN',
	BLOGGER = 'BLOGGER'
}

export enum ADMIN_ROLES_WEIGHTS {
	ADMIN = 1,
	SUPERADMIN = 0,
	BLOGGER = 2
}

export enum LANDING_STATES {
	PARTNER_CREATE = 'PARTNER_CREATE',
	PARTNER_EDIT = 'PARTNER_EDIT',
	TESTIMONIAL_CREATE = 'TESTIMONIAL_CREATE',
	TESTIMONIAL_EDIT = 'TESTIMONIAL_EDIT',
	CHALLENGE_CREATE = 'CHALLENGE_CREATE',
	CHALLENGE_EDIT = 'CHALLENGE_EDIT'
}

export enum LANGUAGE {
	SK = 'sk',
	CZ = 'cz',
	EN = 'en'
}

export enum USER_ROLE {
	ADMIN = 'ADMIN',
	USER = 'USER'
}

export enum VISIBILITY_ON_LANDING_PAGE {
	VISIBLE = 'VISIBLE',
	NOT_VISIBLE = 'NOT_VISIBLE'
}

export enum BLOG_CONTENT_TYPE {
	TEXT = 'TEXT',
	IMAGE = 'IMAGE',
	TITLE = 'TITLE',
	TESTIMONIAL = 'TESTIMONIAL',
	COLORED_BOX = 'COLORED_BOX',
	RECIPE_STEPS = 'RECIPE_STEPS',
	RECIPE_STEP = 'RECIPE_STEP',
	NUTRITION = 'NUTRITION',
	INGREDIENTS = 'INGREDIENTS',
	GRID = 'GRID',
	BUTTON = 'BUTTON',
	DURATION = 'DURATION',
	PORTIONS = 'PORTIONS',
	PORTION_TYPE = 'PORTION_TYPE',
	REDIRECT_ELEMENT = 'REDIRECT_ELEMENT'
}

export enum BLOG_POST_TAG {
	VEGETARIAN = 'VEGETARIAN',
	GLUTEN_FREE = 'GLUTEN_FREE',
	LACTOSE_FREE = 'LACTOSE_FREE'
}

export enum BLOG_CATEGORY {
	MAIN = 'MAIN',
	FAVOURITE_POSTS = 'FAVOURITE_POSTS',
	MOST_READ_POSTS = 'MOST_READ_POSTS',
	INTEREST_POST = 'INTEREST_POST'
}

export enum BLOG_POST_TYPE {
	ARTICLE = 'ARTICLE',
	RECIPE = 'RECIPE'
}

export enum BLOG_POSTS_FILTER_OPTION {
	PUBLISHED = 'published',
	PLANNED = 'planned',
	CONCEPTS = 'concepts',
	DELETED = 'deleted'
}

export enum PREFERENCE_TYPE {
	LIFESTYLE = 'LIFESTYLE',
	LEVEL = 'LEVEL',
	CATEGORY = 'CATEGORY',
	GOAL = 'GOAL'
}

export enum CURRENCY {
	EUR = 'EUR',
	CZK = 'CZK'
}

export enum PAYMENT_CURRENCY_LABEL {
	EUR = '€',
	CZK = 'Kč'
}

export enum PAYMENT_STATE {
	INITIALIZED = 'INITIALIZED',
	FINISHED = 'FINISHED',
	FAILED = 'FAILED',
	PREAUTHORIZED = 'PREAUTHORIZED'
}

export enum PAYMENT_TYPE {
	ONETIME = 'ONETIME',
	ONETIME_MONTH = 'ONETIME_MONTH',
	ONETIME_QUARTER = 'ONETIME_QUARTER',
	ONETIME_YEAR = 'ONETIME_YEAR',
	MONTH = 'MONTH',
	SUBSCRIPTION_INITIAL = 'SUBSCRIPTION_INITIAL',
	SUBSCRIPTION_RECURING = 'SUBSCRIPTION_RECURING',
	SUBSCRIPTION_UPGRADE = 'SUBSCRIPTION_UPGRADE',
	REFUND = 'REFUND',
	MANUAL = 'MANUAL',
	IOS_MONTH = 'IOS_MONTH',
	IOS_QUARTER = 'IOS_QUARTER',
	IOS_YEAR = 'IOS_YEAR',
	ANDROID_MONTH = 'ANDROID_MONTH',
	ANDROID_QUARTER = 'ANDROID_QUARTER',
	ANDROID_YEAR = 'ANDROID_YEAR'
}

export enum FORMS {
	BANNER_FORM = 'BANNER_FORM',
	LOGIN_FORM = 'LOGIN_FORM',
	PASSWORD_CHANGE_FORM = 'PASSWORD_CHANGE_FORM',
	CHANGE_PASSWORD_FORM = 'CHANGE_PASSWORD_FORM',
	FORGOTTEN_PASSWORD_FORM = 'FORGOTTEN_PASSWORD_FORM',
	SET_NEW_PASSWORD_FORM = 'SET_NEW_PASSWORD_FORM',
	INSTRUCTOR_FORM = 'INSTRUCTOR_FORM',
	PROGRAM_FORM = 'PROGRAM_FORM',
	CHALLENGE_FORM = 'CHALLENGE_FORM',
	EXERCISE_FORM = 'EXERCISE_FORM',
	EXERCISE_TARGET_FORM = 'EXERCISE_TARGET_FORM',
	MOTIVATION_ADVICE_FORM = 'MOTIVATION_ADVICE_FORM',
	ACCESSORY_FORM = 'ACCESSORY_FORM',
	USER_FORM = 'USER_FORM',
	USER_PROGRAM_FORM = 'USER_PROGRAM_FORM',
	PAYMENT_FORM = 'PAYMENT_FORM',
	CUSTOM_PAYMENT_FORM = 'CUSTOM_PAYMENT_FORM',
	REFUND_PAYMENTS_FORM = 'REFUND_PAYMENTS_FORM',
	SUBSCRIPTION_FORM = 'SUBSCRIPTION_FORM',
	EDIT_SUBSCRIPTION_FORM = 'EDIT_SUBSCRIPTION_FORM',
	LIVE_STREAM_FORM = 'LIVE_STREAM_FORM',
	LIVESTREAM_CATEGORIES_FORM = 'LIVESTREAM_CATEGORIES_FORM',
	BLOG_FORM = 'BLOG_FORM',
	BLOG_CATEGORY_FORM = 'BLOG_CATEGORY_FORM',
	DOWNLOAD_FORM = 'DOWNLOAD_FORM',
	DOWNLOAD_CATEGORY_FORM = 'DOWNLOAD_CATEGORY_FORM',
	TAG_FORM = 'TAG_FORM',
	VIDEO_FORM = 'VIDEO_FORM',
	SLIDER_FORM = 'SLIDER_FORM',
	SETTINGS_FORM = 'SETTINGS_FORM',
	PAGE_FORM = 'PAGE_FORM',
	LANDING_CHALLENGE_FORM = 'LANDING_CHALLENGE_FORM',
	LANDING_PARTNER_FORM = 'LANDING_PARTNER_FORM',
	LANDING_TESTIMONIAL_FORM = 'LANDING_TESTIMONIAL_FORM',
	LANDING_METATAGS_FORM = 'LANDING_METATAGS_FORM',
	PROMOCODE_FORM = 'PROMOCODE_FORM',
	ADMINISTRATOR_FORM = 'ADMINISTRATOR_FORM',
	DONATION_FORM = 'DONATION_FORM',
	DONATION_EMAIL_FORM = 'DONATION_EMAIL_FORM',
	SUBSCRIPTION_MONTH_FORM = 'SUBSCRIPTION_MONTH_FORM',
	GENERATE_GIF_THUMBNAIL = 'GENERATE_GIF_THUMBNAIL',
	TRANSFER_SUBSCRIPTION_FORM = 'TRANSFER_SUBSCRIPTION_FORM',
	RECIPE_BOOKS_FORM = 'RECIPE_BOOKS_FORM',
	GENERATE_PROMO_CODES = 'GENERATE_PROMO_CODES',
	PUSH_NOTIFICATION_FORM = 'PUSH_NOTIFICATION_FORM',
	PREFERENCE_FORM = 'PREFERENCE_FORM',
	GIFT_PRODUCTS_FORM = 'GIFT_PRODUCTS_FORM'
}

export enum GENDER {
	FEMALE = 'FEMALE',
	MALE = 'MALE',
	NOT_DISCLOSED = 'NOT_DISCLOSED'
}

export enum SOCIAL_LOGIN_TYPE {
	FACEBOOK = 'FACEBOOK',
	GOOGLE = 'GOOGLE'
}

export enum CALENDAR {
	WEEKLY = 'WEEKLY',
	MONTHLY = 'MONTHLY'
}

export enum PASSWORD_STRENGTH {
	WEAK = 'WEAK',
	NORMAL = 'NORMAL',
	THICK = 'THICK'
}

export enum PAGE_TYPE {
	STATIC = 'STATIC',
	DYNAMIC = 'DYNAMIC'
}

export enum LEGAL_DOCUMENT_TYPE {
	COOKIES = 'cookies',
	PRIVACY_POLICY = 'privacyPolicy',
	TERMS_AND_CONDITIONS = 'termsAndConditions'
}

export enum EMPTY_STATE_TYPE {
	LIST = 'LIST',
	WAITING = 'WAITING',
	CERTIFICATE = 'CERTIFICATE'
}

export enum ERROR_STATE_TYPE {
	LIST = 'LIST'
}

export enum PROFILE_MENU {
	PROFILE = 'PROFILE',
	PAYMENTS = 'PAYMENTS',
	MEASUREMENT = 'MEASUREMENT',
	AWARDS = 'AWARDS',
	PASSWORD = 'PASSWORD',
	EXERCISES = 'EXERCISES',
	SUBSCRIPTION = 'SUBSCRIPTION'
}

export enum UPLOAD_CATEGORY {
	TRAINER = 'TRAINER',
	TRAINER_COVER = 'TRAINER_COVER',
	PROGRAM_LOGO = 'PROGRAM_LOGO',
	PROGRAM_COVER = 'PROGRAM_COVER',
	PROGRAM_BADGE_LOGO = 'PROGRAM_BADGE_LOGO',
	EXERCISE_COVER = 'EXERCISE_COVER',
	ACTIVITY_COVER = 'ACTIVITY_COVER',
	CHALLANGE_BANNER = 'CHALLANGE_BANNER',
	CHALLANGE_LARGE_BANNER = 'CHALLANGE_LARGE_BANNER',
	MOTIVATION_ADVICE = 'MOTIVATION_ADVICE',
	ACCESSORIES = 'ACCESSORIES',
	PROFILE = 'PROFILE',
	BLOG = 'BLOG',
	DOWNLOAD = 'DOWNLOAD',
	DOWNLOAD_COVER = 'DOWNLOAD_COVER',
	SLIDER = 'SLIDER',
	LANDING_PAGE = 'LANDING_PAGE',
	PUSH_NOTIFICATION = 'PUSH_NOTIFICATION'
}

export enum FREE_SUBSCRIPTION_REASON {
	FAILURE = 'FAILURE',
	PROMO = 'PROMO',
	ILLNESS = 'ILLNESS',
	PREGNANCY = 'PREGNANCY',
	OTHER = 'OTHER'
}

export enum ORDER_BY_TYPE {
	RECOMMEND = 'recommend',
	NAME = 'name',
	NAME_ASC = 'name_asc',
	NAME_DESC = 'name_desc',
	DURATION = 'duration',
	DURATION_ASC = 'duration_asc',
	DURATION_DESC = 'duration_desc',
	CALORIES = 'calories',
	CALORIES_ASC = 'calories_asc',
	CALORIES_DESC = 'calories_desc',
	POPULARITY = 'popularity',
	VIEWS = 'views',
	PROCESSED_AT = 'processedAt',
	PROCESSED_AT_ASC = 'processed_at_asc',
	PROCESSED_AT_DESC = 'processed_at_desc'
}

export enum ORDER_DIRECTION {
	ASC = 'asc',
	DESC = 'desc'
}

export enum FILTERS {
	SEARCH = 'search',
	DURATION = 'durationSeconds',
	ACCESSORIES = 'accessories',
	TRAINERS = 'trainers',
	PREFERENCES = 'preferences',
	CATEGORIES = 'bodyParts',
	PROGRAMS = 'programs',
	// eslint-disable-next-line @typescript-eslint/no-shadow
	DIFFICULTY = 'difficulty'
}

export enum EXERCISE_ACTION {
	ADD = 'ADD',
	CHANGE = 'CHANGE'
}

export enum PLATFORM_TYPE {
	WEB = 'WEB',
	MOBILE = 'MOBILE',
	TV = 'TV'
}

export const PLATFORM_OPTIONS = [
	{ value: PLATFORM_TYPE.WEB, label: 'Web' },
	{ value: PLATFORM_TYPE.MOBILE, label: 'Mobil' },
	{ value: PLATFORM_TYPE.TV, label: 'TV' }
]

export enum NUMBER_OPERATION_TYPE {
	INCREMENT = 'INCREMENT',
	DECREMENT = 'DECREMENT'
}

export enum ACTIVITY_ACTION {
	ADD = 'ADD',
	CHANGE = 'CHANGE'
}

export enum ONBOARDING_TYPE {
	DASHBOARD = 'DASHBOARD',
	CHALLENGE = 'CHALLENGE',
	ALL = 'ALL'
}

export enum STATISTICS_TYPE {
	WEIGHT = 'weight',
	HIPS = 'hips',
	THIGH = 'thigh',
	WAIST = 'waist'
}

export enum CHALLENGE_MODAL_ACTION {
	PRIVATE = 'PRIVATE',
	COMPETITION = 'COMPETITION'
}

export enum CHALLENGE_EVENT_TYPE {
	START = 'START',
	FINISH = 'FINISH'
}

export enum USER_MEASUREMENTS_TYPE {
	MONTHLY = 'MONTHLY',
	CHALLENGE_START = 'CHALLENGE_START',
	CHALLENGE_FINISH = 'CHALLENGE_FINISH'
}

export enum PAYMENT_METHOD {
	PAYPAL = 'PAYPAL',
	SMS = 'SMS',
	TRUSTPAY_CARD = 'TRUSTPAY_CARD',
	TRUSTPAY_BANK = 'TRUSTPAY_BANK',
	BANK_TRANSACTION = 'BANK_TRANSACTION',
	IOS_IN_APP_PURCHASE = 'IOS_IN_APP_PURCHASE',
	ANDROID_IN_APP_PURCHASE = 'ANDROID_IN_APP_PURCHASE'
}

export enum API_KEYS {
	GOOGLE_API = '1061755657702-p2pgiimm9gnue4v4dfuf6d0c1n4428je.apps.googleusercontent.com',
	FB_API = '666086507534298' // user api grapptest11@gmail.com
}

export enum SMS_STATUS {
	SUCCESS = 'SUCCESS',
	FAILED = 'FAILED'
}

export enum SMS_TYPE {
	NOTIFICATION = 'NOTIFICATION',
	RECURRING = 'RECURRING',
	ACTIVATION = 'ACTIVATION'
}

export enum SMS_CODE_STATUS {
	DEACTIVATED = 'DEACTIVATED'
}

export enum SUBSCRIPTION_TIER {
	PREMIUM = 'PREMIUM',
	VIP = 'VIP'
}

export enum SUBSCRIPTION_TYPE {
	MONTH = 'MONTH',
	QUARTER = 'QUARTER',
	YEAR = 'YEAR',
	PROMOCODE = 'PROMOCODE',
	CUSTOM = 'CUSTOM',
	ONETIME_MONTH = 'ONETIME_MONTH',
	IOS_MONTH = 'IOS_MONTH',
	IOS_QUARTER = 'IOS_QUARTER',
	IOS_YEAR = 'IOS_YEAR',
	ANDROID_MONTH = 'ANDROID_MONTH',
	ANDROID_QUARTER = 'ANDROID_QUARTER',
	ANDROID_YEAR = 'ANDROID_YEAR'
}

export enum SUBSCRIPTION_STATE {
	INITIALIZED = 'INITIALIZED',
	DOWNGRADED = 'DOWNGRADED',
	PREAUTHORIZED = 'PREAUTHORIZED',
	CANCELED = 'CANCELED',
	ACTIVE = 'ACTIVE',
	FAILED = 'FAILED',
	UPGRADED = 'UPGRADED'
}

export enum BANNER_TYPE {
	EXERCISE_DETAIL = 'EXERCISE_DETAIL',
	PROGRAM_DETAIL = 'PROGRAM_DETAIL',
	BLOG_LIST = 'BLOG_LIST',
	TRAINER_DETAIL = 'TRAINER_DETAIL',
	ESHOP_SLIDER = 'ESHOP_SLIDER',
	BLOG_DETAIL = 'BLOG_DETAIL'
}

export enum PAYMENTS_SUBSCRIPTION_TYPE {
	MONTH = 'MONTH',
	QUARTER = 'QUARTER',
	YEAR = 'YEAR',
	PROMOCODE = 'PROMOCODE',
	CUSTOM = 'CUSTOM'
}

export enum PROMOCODE_STATE {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE'
}

export enum PROMOCODE_TYPE {
	FREE_MONTHS = 'FREE_MONTHS',
	DISCOUNT = 'DISCOUNT'
}

export enum PROMOCODE_LIFESPAN {
	LONG_TERM = 'LONG_TERM',
	ONE_TIME = 'ONE_TIME'
}

export enum NAMESPACE {
	COMMON = 'common',
	PATHS = 'paths'
}

export enum VIDEO_CATEGORY_FILTER {
	PREVIEW_PROGRAM = 'PREVIEW_PROGRAM',
	PREVIEW_TRAINER = 'PREVIEW_TRAINER',
	EXERCISE = 'EXERCISE',
	LIVESTREAM = 'LIVESTREAM',
	OTHER = 'OTHER'
}

export enum SUBSCRIPTION_USER_TYPE {
	ALL = 'ALL',
	PREMIUM = 'PREMIUM',
	NON_PREMIUM = 'NON_PREMIUM'
}

export enum INSTRUCTOR_ROLES {
	TRAINER = 'TRAINER',
	NUTRITIONIST = 'NUTRITIONIST',
	COACH = 'COACH'
}

export const INSTRUCTOR_ROLES_OPTIONS = [
	{ value: INSTRUCTOR_ROLES.TRAINER, label: 'Tréner' },
	{ value: INSTRUCTOR_ROLES.NUTRITIONIST, label: 'Výživový poradca' },
	{ value: INSTRUCTOR_ROLES.COACH, label: 'Kouč' }
]

export enum PUSH_NOTIFICATION_STATE {
	DRAFT = 'DRAFT',
	SCHEDULED = 'SCHEDULED',
	PROCESSING = 'PROCESSING',
	SENT = 'SENT',
	FAILED = 'FAILED'
}

export const PUSH_NOTIFICATION_STATE_OPTIONS = [
	{ value: PUSH_NOTIFICATION_STATE.DRAFT },
	{ value: PUSH_NOTIFICATION_STATE.SCHEDULED },
	{ value: PUSH_NOTIFICATION_STATE.PROCESSING },
	{ value: PUSH_NOTIFICATION_STATE.SENT },
	{ value: PUSH_NOTIFICATION_STATE.FAILED }
]

export enum AUDIENCE {
	PREMIUM = 'PREMIUM',
	NON_PREMIUM = 'NON_PREMIUM',
	IOS = 'IOS',
	ANDROID = 'ANDROID',
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE'
}

export const AUDIENCE_OPTIONS = [
	{ value: AUDIENCE.PREMIUM, label: 'Premium' },
	{ value: AUDIENCE.NON_PREMIUM, label: 'Bez premium' },
	{ value: AUDIENCE.IOS, label: 'iOS' },
	{ value: AUDIENCE.ANDROID, label: 'Android' },
	{ value: AUDIENCE.ACTIVE, label: 'Aktívni' },
	{ value: AUDIENCE.INACTIVE, label: 'Neaktívni' }
]

export enum PUSH_NOTIFICATION_TYPE {
	BIRTHDAY = 'BIRTHDAY',
	DAYS_OF_INACTIVITY_3 = 'DAYS_OF_INACTIVITY_3',
	DAYS_OF_INACTIVITY_5 = 'DAYS_OF_INACTIVITY_5',
	DAYS_OF_INACTIVITY_10 = 'DAYS_OF_INACTIVITY_10',
	EXERCISE_REMINDER = 'EXERCISE_REMINDER'
}

export const PUSH_NOTIFICATION_TYPE_OPTIONS = [
	{ value: PUSH_NOTIFICATION_TYPE.BIRTHDAY, label: 'Narodeninová' },
	{ value: PUSH_NOTIFICATION_TYPE.DAYS_OF_INACTIVITY_10, label: '10 dní neaktivity' },
	{ value: PUSH_NOTIFICATION_TYPE.DAYS_OF_INACTIVITY_5, label: '5 dní neaktivity' },
	{ value: PUSH_NOTIFICATION_TYPE.DAYS_OF_INACTIVITY_3, label: '3 dni neaktivity' },
	{ value: PUSH_NOTIFICATION_TYPE.EXERCISE_REMINDER, label: 'Pripomienka cvičenia' }
]

// Planeat
export enum RECIPE_BOOK_TYPE {
	STANDARD = 'standard',
	BONUS = 'bonus'
}

export enum PLANEAT_GENDER {
	MALE = 'male',
	FEMALE = 'female'
}
export enum PLANEAT_FITNESS_GOAL {
	LOSE_WEIGHT = 'LOSE_WEIGHT',
	MODERATE_LOSE_WEIGHT = 'MODERATE_LOSE_WEIGHT',
	MAINTAIN_WEIGHT = 'MAINTAIN_WEIGHT',
	MODERATE_GAIN_WEIGHT = 'MODERATE_GAIN_WEIGHT',
	GAIN_WEIGHT = 'GAIN_WEIGHT'
}
export enum PLANEAT_WORK_DIFFICULTY {
	VERY_EASY = 'VERY_EASY',
	EASY = 'EASY',
	MEDIUM = 'MEDIUM',
	HIGH = 'HIGH',
	VERY_HIGH = 'VERY_HIGH'
}
export enum PLANEAT_EXERCISE_ACTIVITY {
	NO_EXERCISE = 'NO_EXERCISE',
	LIGHT_EXERCISE = 'LIGHT_EXERCISE',
	MODERATE_EXERCISE = 'MODERATE_EXERCISE',
	HEAVY_EXERCISE = 'HEAVY_EXERCISE',
	VERY_HEAVY_EXERCISE = 'VERY_HEAVY_EXERCISE'
}
export enum PLANEAT_STRESS_LEVEL {
	LOW = 'LOW',
	MEDIUM = 'MEDIUM',
	HIGH = 'HIGH'
}

export enum PROGRAM_DIFFICULTY {
	NORMAL = 'NORMAL',
	HARD = 'HARD'
}

export enum PROGRAM_EXERCISE_DIFFICULTY {
	NORMAL = 'NORMAL',
	HARD = 'HARD'
}

export enum VIDEO_DIFFICULTY {
	EASY = 'EASY',
	MEDIUM = 'MEDIUM',
	HARD = 'HARD',
	EDUCATIONAL = 'EDUCATIONAL'
}

export enum VIDEO_MEDIUM_DIFFICULTY {
	LIGHT = 'LIGHT',
	HARD = 'HARD'
}

export enum PROMO_CODE_TARGET_DISCOUNT {
	RECURRING = 'RECURRING',
	ONE_TIME = 'ONE_TIME'
}

export const DEFAULT_LOCALE = 'sk'

export const ENDPOINTS = {
	// -- ADMIN --
	// profile
	PROFILE: '/admin/profile',
	// authorization
	LOGIN: '/admin/authorization/login',
	ADMIN_CHANGE_PASSWORD: '/admin/authorization/change-password',
	ADMIN_FORGOTTEN_PASSWORD: '/admin/authorization/forgotten-password',
	// dashboard
	GET_DASHBOARD: '/admin/dashboard',

	// instructors
	GET_ADMIN_INSTRUCTORS: '/admin/trainers',
	CREATE_INSTRUCTOR: '/admin/trainers/',
	GET_INSTRUCTOR: (id: number) => `/admin/trainers/${id}`,
	UPDATE_INSTRUCTOR: (id: number) => `/admin/trainers/${id}`,
	DELETE_INSTRUCTOR: (id: number) => `/admin/trainers/${id}`,

	// programs
	GET_ADMIN_PROGRAMS: '/admin/programs',
	CREATE_PROGRAM: '/admin/programs',
	GET_ADMIN_PROGRAM: (id: number) => `/admin/programs/${id}`,
	UPDATE_PROGRAM: (id: number) => `/admin/programs/${id}`,
	DELETE_PROGRAM: (id: number) => `/admin/programs/${id}`,
	GET_PROGRAM_USERS: (id: number) => `/admin/programs/${id}/users`,
	// challenges
	GET_CHALLENGE: (id: number) => `/admin/challenges/${id}`,
	CREATE_CHALLENGE: '/admin/challenges',
	UPDATE_CHALLENGE: (id: number) => `/admin/challenges/${id}`,
	DELETE_CHALLENGE: (id: number) => `/admin/challenges/${id}`,
	// exercises
	GET_ADMIN_EXERCISES: '/admin/exercises',
	CREATE_EXERCISE: '/admin/exercises/',
	EXPORT_CHALLENGE_USERS: (id: number) => `/admin/exports/programs/${id}/users`,
	CREATE_BANNER: '/admin/headlines',
	UPDATE_BANNER: (id: number) => `/admin/headlines/${id}`,
	GET_BANNERS: '/admin/headlines',
	GET_BANNER: (id: number) => `/admin/headlines/${id}`,
	DELETE_BANNER: (id: number) => `/admin/headlines/${id}`,

	// upload files
	UPLOAD_SIGNED_REQUEST: '/api/v1/upload/sign',

	GET_EXERCISE: (id: number) => `/admin/exercises/${id}`,
	UPDATE_EXERCISE: (id: number) => `/admin/exercises/${id}`,
	DUPLICATE_EXERCISE: (id: number) => `/admin/exercises/${id}/duplicate`,
	DELETE_EXERCISE: (id: number) => `/admin/exercises/${id}`,

	// targets
	GET_ADMIN_EXERCISE_TARGETS: '/admin/target',
	CREATE_ADMIN_EXERCISE_TARGET: '/admin/target',
	GET_ADMIN_EXERCISE_TARGET: (id: number) => `/admin/target/${id}`,
	UPDATE_ADMIN_EXERCISE_TARGET: (id: number) => `/admin/target/${id}`,
	DELETE_ADMIN_EXERCISE_TARGET: (id: number) => `/admin/target/${id}`,
	// motivation advices
	GET_MOTIVATION_ADVICES: '/admin/motivation-advices',
	CREATE_MOTIVATION_ADVICE: '/admin/motivation-advices',
	GET_MOTIVATION_ADVICE: (id: number) => `/admin/motivation-advices/${id}`,
	UPDATE_MOTIVATION_ADVICE: (id: number) => `/admin/motivation-advices/${id}`,
	DELETE_MOTIVATION_ADVICE: (id: number) => `/admin/motivation-advices/${id}`,
	// accessories
	GET_ACCESSORIES: '/admin/accessories',
	CREATE_ACCESSORY: '/admin/accessories',
	GET_ACCESSORY: (id: number) => `/admin/accessories/${id}`,
	UPDATE_ACCESSORY: (id: number) => `/admin/accessories/${id}`,
	DELETE_ACCESSORY: (id: number) => `/admin/accessories/${id}`,

	// users
	GET_USERS: '/admin/users',
	CREATE_USER: '/admin/users',
	GET_USER: (id: number) => `/admin/users/${id}`,
	UPDATE_USER: (id: number) => `/admin/users/${id}`,
	DELETE_USER: (id: number) => `/admin/users/${id}`,
	SWITCH_USER: (id: number) => `/admin/users/${id}/login`,
	ADD_SUBSCRIPTION: (id: number) => `/admin/users/${id}/subscriptions`,
	CANCEL_SUBSCRIPTION: (userId: number, subscriptionId: number) => `/admin/users/${userId}/subscriptions/${subscriptionId}/cancel`,
	REMOVE_SUBSCRIPTION: (userId: number, subscriptionId: number) => `/admin/users/${userId}/subscriptions/${subscriptionId}`,
	GET_USER_SUBSCRIPTIONS_TIER_VALIDATE: (userID: number) => `/admin/users/${userID}/subscriptions/tier/validate`,
	GET_USER_SUBSCRIPTIONS_TIER: (userID: number) => `/admin/users/${userID}/subscriptions/tier`,
	CHANGE_USER_SUBSCRIPTIONS_TIER: (userID: number) => `/admin/users/${userID}/subscriptions/tier`,
	GET_USER_PROGRAMS: (id: number) => `/admin/users/${id}/programs`,
	GET_USER_USER_AGENTS: (id: number) => `/admin/users/${id}/user-agents`,
	GET_USER_PROGRAM: (userId: number, programID: number) => `/admin/users/${userId}/programs/${programID}`,
	UPDATE_USER_PROGRAM: (userId: number, programID: number) => `/admin/users/${userId}/programs/${programID}`,
	DELETE_USER_PROGRAM: (userId: number, programID: number) => `/admin/users/${userId}/programs/${programID}`,
	ADMIN_CHANGE_USER_PASSWORD: (id: number) => `/admin/users/${id}/password`,

	// push notifications
	GET_PUSH_NOTIFICATIONS: '/admin/push-notifications',
	GET_PUSH_NOTIFICATION: (id: number) => `/admin/push-notifications/${id}`,
	CREATE_PUSH_NOTIFICATION: '/admin/push-notifications/',
	UPDATE_PUSH_NOTIFICATION: (id: number) => `/admin/push-notifications/${id}`,
	DELETE_PUSH_NOTIFICATION: (id: number) => `/admin/push-notifications/${id}`,
	GET_PUSH_NOTIFICATIONS_STATS: '/admin/statistics/notifications',

	// payments
	GET_PAYMENTS: '/admin/payments',
	CREATE_CUSTOM_PAYMENT: (userID: number) => `/admin/payments/${userID}/invoice`,
	EXPORT_PAYMENTS: '/admin/payments/export',
	GET_PAYMENT: (id: number) => `/admin/payments/${id}`,
	GET_PAYMENT_INVOICE: (id: number) => `/admin/payments/${id}/invoice`,
	REFUND_PAYMENTS: '/admin/payments/refund',
	REFUND_PAYMENT: (id: number) => `/admin/payments/${id}/refund`,
	EXECUTE_PAYMENT: (id: number) => `/admin/payments/${id}/execute`,
	DEACTIVATE_SMS_CODE: '/admin/payments/code-deactivate',
	// subscriptions
	GET_DONATIONS: '/admin/subscriptions/donations',
	GET_DONATION: (id: number) => `/admin/subscriptions/donations/${id}`,
	GET_SUBSCRIPTIONS: '/admin/subscriptions',
	TRANSFER_SUBSCRIPTION: (userId: number) => `/admin/users/${userId}/subscriptions/transfer`,
	GET_LIVESTREAM_CATEGORIES: '/admin/live-stream-categories',
	UPDATE_LIVESTREAM_CATEGORIES: '/admin/live-stream-categories',
	// blog
	GET_ADMIN_BLOG_POSTS: '/admin/blog-posts',
	GET_ADMIN_BLOG_POST: (id: number) => `/admin/blog-posts/${id}`,
	CREATE_BLOG_POST: '/admin/blog-posts/',
	DUPLICATE_BLOG_POST: (id: number) => `/admin/blog-posts/${id}/duplicate`,
	UPDATE_BLOG_POST: (id: number) => `/admin/blog-posts/${id}`,
	DELETE_BLOG_POST: (id: number) => `/admin/blog-posts/${id}`,
	RESTORE_BLOG_POST: (id: number) => `/admin/blog-posts/${id}/restore`,
	GET_ADMIN_BLOG_CATEGORIES: '/admin/blog-categories',
	GET_ADMIN_BLOG_CATEGORY: (id: number) => `/admin/blog-categories/${id}`,
	CREATE_ADMIN_BLOG_CATEGORY: '/admin/blog-categories',
	UPDATE_ADMIN_BLOG_CATEGORY: (id: number) => `/admin/blog-categories/${id}`,
	DELETE_ADMIN_BLOG_CATEGORY: (id: number) => `/admin/blog-categories/${id}`,
	// downloads
	GET_ADMIN_DOWNLOADS: '/admin/downloads',
	GET_ADMIN_DOWNLOAD: (id: number) => `/admin/downloads/${id}`,
	CREATE_DOWNLOAD: '/admin/downloads',
	UPDATE_DOWNLOAD: (id: number) => `/admin/downloads/${id}`,
	DELETE_DOWNLOAD: (id: number) => `/admin/downloads/${id}`,

	// download categories
	GET_ADMIN_DOWNLOAD_CATEGORIES: '/admin/download-categories',
	GET_ADMIN_DOWNLOAD_CATEGORY: (id: number) => `/admin/download-categories/${id}`,
	CREATE_ADMIN_DOWNLOAD_CATEGORY: '/admin/download-categories',
	UPDATE_ADMIN_DOWNLOAD_CATEGORY: (id: number) => `/admin/download-categories/${id}`,
	DELETE_DOWNLOAD_CATEGORY: (id: number) => `/admin/download-categories/${id}`,

	// recipe books
	GET_RECIPE_BOOKS: (userId: number) => `/admin/users/${userId}/nutrition-plans/`,
	ODRED_STANDARD_RECIPE_BOOK: (userId: number) => `/admin/users/${userId}/nutrition-plans/standard-order`,
	ORDER_BONUS_RECIPE_BOOK: (userId: number) => `/admin/users/${userId}/nutrition-plans/bonus-order`,

	// tags
	GET_TAGS: '/admin/tags/',
	CREATE_TAG: '/admin/tags/',
	GET_TAG: (id: number) => `/admin/tags/${id}`,
	UPDATE_TAG: (id: number) => `/admin/tags/${id}`,
	DELETE_TAG: (id: number) => `/admin/tags/${id}`,
	// videos
	GET_VIDEOS: '/admin/videos',
	CREATE_VIDEO: '/admin/videos',
	GET_VIDEO: (id: number) => `/admin/videos/${id}`,
	UPDATE_VIDEO: (id: number) => `/admin/videos/${id}`,
	DELETE_VIDEO: (id: number) => `/admin/videos/${id}`,
	GET_PREFFERED_LIVESTREAM: '/admin/videos/preferred-stream',
	// thumbnails
	GENERATE_THUMBNAIL: (id: number) => `/admin/videos/${id}/thumbnails`,
	GENERATE_THUMBNAIL_ANIMATION: (id: number) => `/admin/videos/${id}/animatedThumbnails`,
	CHECK_THUMBNAIL_GENERATION: (id: number) => `/admin/videos/${id}/animatedThumbnails/status`,
	SAVE_THUMBNAIL_ANIMATION: (id: number) => `/admin/videos/${id}/animatedThumbnails`,
	// slider
	GET_SLIDER: '/admin/slider',
	CREATE_SLIDE: '/admin/slider',
	GET_SLIDE: (id: number) => `/admin/slider/${id}`,
	UPDATE_SLIDE: (id: number) => `/admin/slider/${id}`,
	DELETE_SLIDE: (id: number) => `/admin/slider/${id}`,
	// settings
	UPDATE_PRICELIST: '/admin/settings/pricelist',
	// pages
	GET_PAGES: '/admin/pages',
	GET_PAGE: (id: number) => `/admin/pages/${id}`,
	UPDATE_PAGE: (id: number) => `/admin/pages/${id}`,
	DELETE_PAGE: (id: number) => `/admin/pages/${id}`,
	DUPLICATE_PAGE: (id: number) => `/admin/pages/${id}/duplicate`,
	// landing
	GET_LANDING_PAGE: '/admin/landing',

	// promo codes
	GET_PROMO_CODES: '/admin/promo-codes',
	CREATE_PROMO_CODES: '/admin/promo-codes',
	GET_PROMO_CODE: (id: number) => `/admin/promo-codes/${id}`,
	UPDATE_PROMO_CODES: (id: number) => `/admin/promo-codes/${id}`,
	GET_PROMO_CODES_USAGE: '/admin/promo-codes/applications',
	GENERATE_PROMO_CODES: '/admin/promo-codes/batch',

	// administrators
	ADMINISTRATORS: '/admin/administrators',
	ADMINISTRATOR_CREATE: '/admin/administrators/',
	ADMINISTRATOR: (id: number) => `/admin/administrators/${id}`,
	// translations
	GET_TRANSLATIONS: '/admin/translations',
	UPDATE_TRANSLATION: '/admin/translations',
	// preferences
	GET_ADMIN_PREFERENCES: '/admin/preferences/',
	CREATE_PREFERENCE: '/admin/preferences/',
	GET_PREFERENCE: (id: number) => `/admin/preferences/${id}`,
	UPDATE_PREFERENCE: (id: number) => `/admin/preferences/${id}`,
	DELETE_PREFERENCE: (id: number) => `/admin/preferences/${id}`,

	// statistics
	STATISTICS: '/admin/statistics',
	SUBSCRIPTION_STATISTICS: '/admin/statistics/subscriptions',
	PROGRAMS_ENGAGEMENT_STATISTICS: 'admin/statistics/programs/engagements',
	PROGRAM_ENGAGEMENT_TREND_STATISTICS: 'admin/statistics/programs/engagements/trends',
	EXERCISES_VIEW_STATISTICS: 'admin/statistics/exercises/views',
	EXERCISES_TREND_STATISTICS: 'admin/statistics/exercises/views/trends',
	EXERCISES_FAVOURITES_STATISTICS: 'admin/statistics/exercises/favourites',
	PAYMENTS_STATISTICS: 'admin/statistics/payments',
	RECIPE_BOOKS_STATISTICS: 'admin/statistics/nutrition-plans',

	// -- API --
	// version
	VERSION: '/api/v1/version',
	// register
	REGISTER: '/api/v1/register',
	CONFIRM_REGISTER: '/api/v1/register/confirm',
	VALIDATE_EMAIL: '/api/v1/register/validate-email',
	RESEND_EMAIL: '/api/v1/register/resend-email',
	// authorization
	REGISTRATION_CONFIRM: 'admin/authorization/registration-confirm',
	FORGOTTEN_PASSWORD: '/api/v1/authorization/forgotten-password',
	RESET_PASSWORD: 'admin/authorization/password',
	SET_NEW_PASSWORD: '/api/v1/authorization/password',
	CHANGE_PASSWORD: '/api/v1/authorization/change-password',
	SOCIAL_LOGIN: '/api/v1/authorization/login/social',
	// profile
	UPDATE_PROFILE: '/api/v1/profile',
	SET_PROFILE_PREFERENCES: '/api/v1/profile/preferences',
	SUBSCRIPTION: '/api/v1/profile/subscription',
	MEASUREMENTS: '/api/v1/profile/measurements',
	CERTIFICATES: '/api/v1/profile/certificates',
	// programs
	GET_PROGRAMS: '/api/v1/programs',
	GET_PROGRAMS_SUGGESTIONS: '/api/v1/programs/suggestions',
	GET_PROGRAM: (urlSlug: string) => `/api/v1/programs/${urlSlug}`,
	SET_NEW_PROGRAM: (id: number) => `/api/v1/profile/programs/${id}`,
	CHANGE_PROGRAM: (id: number) => `/api/v1/profile/programs/${id}`,
	// challenges
	CHALLENGES: '/api/v1/challenges/calendar',
	CHALLENGE_START: (id: number) => `/api/v1/challenges/${id}/start`,
	CHALLENGE_CANCEL: (id: number) => `/api/v1/challenges/${id}/cancel`,
	BEFORE_CHALLENGE_MEASUREMENTS: (id: number) => `/api/v1/challenges/${id}/start/measurements`,
	AFTER_CHALLENGE_MEASUREMENTS: (id: number) => `/api/v1/challenges/${id}/finish`,
	// exercises
	SET_VIDEO_SEEN: (urlSlug: string) => `/api/v1/exercises/${urlSlug}/finish`,
	SET_VIDEO_FAVOURITE: (urlSlug: string) => `/api/v1/exercises/${urlSlug}/favourite`,
	EXERCISE: (urlSlug: string) => `/api/v1/exercises/${urlSlug}`,
	GET_EXERCISES: '/api/v1/exercises',
	GET_FAVOURITE_EXERCISES: '/api/v1/exercises/favourite',
	// calendar
	CALENDAR: '/api/v1/calendar',
	CALENDAR_EXERCISES: '/api/v1/calendar/exercises',
	CALENDAR_EXERCISE: (id: number) => `/api/v1/calendar/exercises/${id}`,
	CALENDAR_EXERCISE_CHANGE: '/api/v1/calendar/exercises/change',
	CALENDAR_ACTIVITIES: '/api/v1/calendar/activities',
	CALENDAR_ACTIVITY: (id: number) => `/api/v1/calendar/activities/${id}`,
	// activities
	ACTIVITIES: '/api/v1/activities',
	// blog
	GET_BLOG_POSTS: '/api/v1/blog/posts',
	GET_BLOG_POST: (urlSlug: string) => `/api/v1/blog/posts/${urlSlug}`,
	// downloads
	GET_DOWNLOADS: '/api/v1/downloads',

	// settings
	GET_SETTINGS: '/api/v1/settings',

	// gift products
	GIFT_PRODUCTS: '/admin/gift-products',

	// landing
	GET_LANDING: '/api/v1/home',
	// preferences
	GET_PREFERENCES: '/api/v1/preferences',

	// certificates
	GET_CERTIFICATE: (id: number) => `/api/v1/certificates/${id}`,
	GET_PUBLIC_CERTIFICATE: (urlSlug: string) => `/api/v1/certificates/public/${urlSlug}`,
	// legal documents
	GET_COOKIES: '/api/v1/legal-documents/cookies',
	GET_PRIVACY_POLICY: '/api/v1/legal-documents/privacy-policy',
	GET_TERM_CONDITIONS: '/api/v1/legal-documents/terms-and-conditions',
	// lists
	LIST_GET_EXERCISES: '/admin/exercises/search',
	LIST_GET_PREFFERENCES: '/admin/preferences/search',
	LIST_GET_ACCESSORIES: '/admin/accessories/search',
	LIST_GET_TRAINERS: '/admin/trainers/search',
	LIST_GET_MOTIVATION_ADVICE: '/admin/motivation-advices/search',
	LIST_GET_TARGETS: '/admin/target/search',
	LIST_GET_LIVESTREAM_CATEGORIES: '/admin/live-stream-categories/search',
	LIST_GET_BLOG_POSTS: '/admin/blog-posts/search',
	LIST_GET_BLOG_CATEGORIES: '/admin/blog-categories/search',
	LIST_GET_ESHOP_PRODUCTS: '/admin/upsell-products/search',
	LIST_GET_PROGRAMS: '/admin/programs/search',
	LIST_GET_VIDEOS: '/admin/videos/search',
	LIST_GET_GIFT_PRODUCTS: '/admin/gift-products/search'
}

export const PAYMENT_METHODS = Object.values(PAYMENT_METHOD)
export const SUBSCRIPTION_TYPES = Object.values(SUBSCRIPTION_TYPE)
export const PAYMENTS_SUBSCRIPTION_TYPES = Object.values(PAYMENTS_SUBSCRIPTION_TYPE)
export const PAYMENT_TYPES = Object.values(PAYMENT_TYPE)
export const PROMOCODE_STATES = Object.values(PROMOCODE_STATE)
export const PROMOCODE_TYPES = Object.values(PROMOCODE_TYPE)
export const PROMOCODE_LIFESPANS = Object.values(PROMOCODE_LIFESPAN)
export const SUBSCRIPTION_PAYMENT_METHOD = ['PAYPAL', 'TRUSTPAY_CARD', 'TRUSTPAY_BANK', 'SMS']
export const SUBSCRIPTION_PAYMENT_TYPE = ['MONTH', 'QUARTER', 'YEAR']
export const SUBSCRIPTION_PAYMENT_CURRENCY = ['EUR', 'CZK']
export const SUBSCRIPTION_PAYMENT_CURRENCY_LABEL = ['€', 'Kč']
export const PREFERENCE_TYPES = Object.values(PREFERENCE_TYPE)
export const DEFAULT_VIMEO_STREAM_LINK = 'https://vimeo.com/event/2158224'
export const LANGUAGES = Object.values(LANGUAGE)

export const getRouteAllowedRoles = () => ({
	[i18next.t('paths:base|key')]: ADMIN_ROLE.BLOGGER,
	[i18next.t('paths:cards|key')]: ADMIN_ROLE.ADMIN,
	[i18next.t('paths:dashboard|key')]: ADMIN_ROLE.SUPERADMIN,
	[i18next.t('paths:instructors|key')]: ADMIN_ROLE.ADMIN,
	[i18next.t('paths:programs|key')]: ADMIN_ROLE.ADMIN,
	[i18next.t('paths:exercises|key')]: ADMIN_ROLE.ADMIN,
	[i18next.t('paths:exerciseTargets|key')]: ADMIN_ROLE.ADMIN,
	[i18next.t('paths:accessories|key')]: ADMIN_ROLE.ADMIN,
	[i18next.t('paths:donations|key')]: ADMIN_ROLE.ADMIN,
	[i18next.t('paths:banners|key')]: ADMIN_ROLE.ADMIN,
	// [i18next.t('paths:giftProducts|key')]: ADMIN_ROLE.ADMIN,
	[i18next.t('paths:motivationAdvices|key')]: ADMIN_ROLE.ADMIN,
	[i18next.t('paths:users|key')]: ADMIN_ROLE.ADMIN,
	[i18next.t('paths:tags|key')]: ADMIN_ROLE.ADMIN,
	[i18next.t('paths:videos|key')]: ADMIN_ROLE.ADMIN,
	[i18next.t('paths:payments|key')]: ADMIN_ROLE.ADMIN,
	// [i18next.t('paths:liveStreams|key')]: ADMIN_ROLES.ADMIN,
	[i18next.t('paths:liveStreamCategories|key')]: ADMIN_ROLE.ADMIN,
	[i18next.t('paths:blog|key')]: ADMIN_ROLE.BLOGGER,
	[i18next.t('paths:blogCategories|key')]: ADMIN_ROLE.BLOGGER,
	[i18next.t('paths:downloads|key')]: ADMIN_ROLE.ADMIN,
	[i18next.t('paths:downloadCategories|key')]: ADMIN_ROLE.ADMIN,
	[i18next.t('paths:slider|key')]: ADMIN_ROLE.ADMIN,
	[i18next.t('paths:settings|key')]: ADMIN_ROLE.SUPERADMIN,
	[i18next.t('paths:pages|key')]: ADMIN_ROLE.ADMIN,
	[i18next.t('paths:landing|key')]: ADMIN_ROLE.ADMIN,
	[i18next.t('paths:promoCodes|key')]: ADMIN_ROLE.SUPERADMIN,
	[i18next.t('paths:promoCodesUsage|key')]: ADMIN_ROLE.SUPERADMIN,
	[i18next.t('paths:administrators|key')]: ADMIN_ROLE.SUPERADMIN,
	[i18next.t('paths:translations|key')]: ADMIN_ROLE.SUPERADMIN,
	[i18next.t('paths:preferences|key')]: ADMIN_ROLE.ADMIN,
	[i18next.t('paths:customers|key')]: ADMIN_ROLE.ADMIN,
	[i18next.t('paths:pushNotifications|key')]: ADMIN_ROLE.ADMIN,
	[i18next.t('paths:pushNotificationsStats|key')]: ADMIN_ROLE.ADMIN
})

export const INPUT_MAX_LENGTH = 255

export const STATIC_DATES: any = {
	TODAY: dayjs(),
	YESTERDAY: dayjs().subtract(1, 'day'),
	MONTH_AGO: dayjs().subtract(1, 'month'),
	START_OF_MONTH: dayjs().startOf('month'),
	END_OF_MONTH: dayjs().endOf('month')
}

export const LIMITS = {
	NOTIFICATION_EDIT: 30
}

export const slugErrorText = 'Reťazec obsahuje nepovolené znaky'
